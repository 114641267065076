import React, { useState, useEffect } from "react";
import heroImage from "../images/P.png";
import { Roll } from "react-reveal";
import StarryBackground from "./Background";
import {
  CircularProgress,
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  useMediaQuery,
  useTheme,
  CardActionArea,
} from "@mui/material";

// Data for the cards
const cardData = [
  {
    title: "Earn ARB Rewards",
    description:
      "Receive Arbitrum rewards automatically by holding pARB tokens.",
  },
  {
    title: "Auto-Rewards Distribution",
    description:
      "Enjoy hassle-free distribution of rewards directly to your wallet.",
  },
  {
    title: "Long-Term Benefits",
    description:
      "Benefit from the long-term value growth and stability of pARB.",
  },
];

const useStyles = (theme) => ({
  comingSoon: {
    cursor: "pointer",
    transition: "transform 0.3s ease-in-out",
    "&:hover": { transform: "scale(1.1)" },
  },
  heroBox: {
    maxWidth: "1200px",
    margin: "auto",
    padding: "4rem 2rem 16rem",
    height: "100vh",

    position: "relative",
    overflow: "hidden",

    "&::before": {
      content: '""',
      position: "absolute",
      top: "-50%",
      left: "-50%",
      width: "200%",
      height: "200%",

      borderRadius: "50%",
      animation: "morph 20s infinite linear",
    },
  },
  heroText: {
    fontWeight: "bold",
    marginBottom: 2,
    textShadow: "2px 2px 4px rgba(0,0,0,0.3)",
  },
  heroImage: {
    maxWidth: "100%",
    height: "auto",
  },
  card: {
    transition: "transform 0.3s ease-in-out",
    "&:hover": { transform: "scale(1.05)" },
    boxShadow: "0px 10px 15px rgba(0,0,0,0.2)",
    position: "relative",
    overflow: "hidden",

    "&::after": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background:
        "linear-gradient(45deg, transparent, rgba(255, 255, 255, 0.3), transparent)",
      opacity: 0,
      transition: "opacity 0.3s",
    },
    "&:hover::after": {
      opacity: 1,
    },
  },
});

const RewardCard = ({ title, description, classes }) => (
  <Grid item xs={12} sm={6} md={4}>
    <Card sx={classes.card}>
      <CardActionArea>
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={classes.heroText}
          >
            {title}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  </Grid>
);

const Hero = () => {
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const classes = useStyles(theme);

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 3000); // Simulate loading time
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Box sx={classes.heroBox}>
        <section className={isMobile ? "hero-section" : "hidden lg:flex"}>
          <Box className="flex items-center justify-between w-full">
            <Box>
              <Typography variant="h3" sx={classes.heroText}>
                Buy $pARB Earn $ARB
              </Typography>
              <Typography variant="body1" sx={classes.heroText}>
                Explore our revolutionary rewards system, where holding pARB
                tokens entitles you to receive ARB rewards.
              </Typography>
            </Box>
            <Roll duration={4000}>
              <Box sx={{ pr: "2rem" }}>
                <img
                  src={heroImage}
                  alt="Illustration"
                  style={classes.heroImage}
                />
              </Box>
            </Roll>
          </Box>
        </section>
        <Box sx={{ textAlign: "center", my: 4 }}>
          <Typography variant="h4" sx={classes.heroText}>
            Presale Coming Soon
          </Typography>
          <Typography variant="h4" sx={classes.heroText}>
            NFT Launch Coming Soon
          </Typography>
        </Box>
        <Grid container spacing={4} sx={{ pt: 8 }}>
          {cardData.map((data) => (
            <RewardCard key={data.title} {...data} classes={classes} />
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default Hero;

// Add this CSS to your index.html or a global CSS file
const globalStyles = `
  @keyframes morph {
    0%, 100% {
      transform: translate(0, 0) rotate(0) scale(1);
    }
    25% {
      transform: translate(-5%, 10%) rotate(20deg) scale(1.1);
    }
    50% {
      transform: translate(5%, -10%) rotate(-20deg) scale(0.9);
    }
    75% {
      transform: translate(-10%, 5%) rotate(10deg) scale(1.05);
    }
  }
`;
