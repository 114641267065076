import React, { useState } from "react";
import { AiOutlineClose, AiOutlineMail, AiOutlineMenu } from "react-icons/ai";
import {
  FaGithub,
  FaLinkedinIn,
  FaFacebook,
  FaTwitter,
  FaDiscord,
  FaTelegramPlane,
} from "react-icons/fa";
import NavLogo from "../images/logo.png";
import { Link } from "react-scroll";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const [navBg, setNavBg] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  const changeNavbg = () => {
    if (window.scrollY >= 90) {
      setNavBg(true);
    } else {
      setNavBg(false);
    }
  };

  window.addEventListener("scroll", changeNavbg);

  return (
    <div
      className={
        navBg
          ? "fixed w-full h-20 shadow-xl z-[100] bg-[#0D0D2B] ease-in-out duration-300"
          : "fixed w-full h-20 z-[100]"
      }
    >
      <div className="flex justify-between items-center w-full h-full max-w-[1200px] m-auto px-2">
        <Link to="hero" smooth={true} offset={50} duration={500}>
          <div className="flex items-center py-2">
            <h1 className="ml-4 text-xl cursor-pointer">Print Arb</h1>
          </div>
        </Link>
        <div className="flex items-center">
          <a
            href=" https://twitter.com/ArbPrintoffical"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTwitter className="mx-2 text-xl cursor-pointer" />
          </a>
          <a
            href="https://discord.gg/EhHNEMR4Sh"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaDiscord className="mx-2 text-xl cursor-pointer" />
          </a>
          <a
            href="https://t.me/PrintArb"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTelegramPlane className="mx-2 text-xl cursor-pointer" />
          </a>
          <button className="mx-2 px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-gray-600 cursor-not-allowed">
            DApp Coming Soon
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
    </div>
  );
};

export default Navbar;
